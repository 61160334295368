import { defineStore } from "pinia"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { _3dFirebaseApi } from "@evercam/shared/api/3dFirebaseApi"
import { IngestApi } from "@evercam/shared/api/ingestApi"
import moment from "moment-timezone"
import { useSnapshotStore } from "@/stores/snapshots"
import { useRecordingsStore } from "@/stores/recordings"
import {
  AnalyticsEvent,
  Camera,
  CameraExid,
  CameraFeatureFlag,
  CameraStatus,
  CameraStatusLog,
  Comment,
  CommentsRequestPayload,
  Media,
  MediaFilterQueryParams,
  MediaStatus,
  MobileCaptureSnapshot,
  PaginatedItems,
  Project,
  TaskStatus,
  TimelineGroup,
  TimelineGroupId,
  TimelineMediaHubItemsByType,
  TimelineOverlayData,
  TimelineOverlayType,
  TimelinePlayerGroupConfig,
  TimelineUrlParam,
  TimelineUrlParams,
  UrlParamValue,
} from "@evercam/shared/types"
import { useNuxtApp } from "#app"
import { camelizeKeys } from "humps"
import { useCameraStore } from "@/stores/camera"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import { useTimelineAnprGroupStore } from "@/stores/timeline/timelineAnprGroup"
import { useTimeline360GroupStore } from "@/stores/timeline/timeline360Group"
import { useTimelineDroneGroupStore } from "@/stores/timeline/timelineDroneGroup"
import { useTimelineMediaHubGroupStore } from "@/stores/timeline/timelineMediaHubGroup"
import { useTimelineBimGroupStore } from "@/stores/timeline/timelineBimGroup"
import { useTimelineMobileCaptureGroupStore } from "@/stores/timeline/timelineMobileCaptureGroup"
import { useTimelineExNvrGroupStore } from "@/stores/timeline/timelineExNvrGroup"
import { useTimelineSiteActivityGroupStore } from "@/stores/timeline/timelineSiteActivityGroup"
import { useTimelineSafetyReportsGroupStore } from "@/stores/timeline/timelineSafetyReportsGroup"
import { useTimelineConstructionReportsGroupStore } from "@/stores/timeline/timelineConstructionReportsGroup"
import { useTimelineCommentsGroupStore } from "@/stores/timeline/timelineCommentsGroup"
import { useTimelineLuminanceGroupStore } from "@/stores/timeline/timelineLuminanceGroup"
import { useCopilotStore } from "@/stores/copilot"
import { TimelineChartType } from "@evercam/ui"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { INGEST_MOBILE_PHOTO_ASSET_TYPE } from "@evercam/shared/constants/ingest"
import { useTimelineObjectInspectorGroupStore } from "@/stores/timeline/timelineObjectInspectorGroup"

interface TimelineState {
  isQueryParamsEmpty: boolean
  showOverlay: boolean
  overlayType?: TimelineOverlayType
  overlayData?: TimelineOverlayData<TimelineOverlayType>
  _360Id?: string
  droneId?: string
  mediaId?: string
  fromDate?: string
  toDate?: string
  timestamp?: string
  isTimelineInitialized: boolean
  showCompare: boolean
  snapshotsInterval: { from: string; to: string }
  groupsStatuses: Record<TimelineGroupId, TaskStatus>
  cameraExid?: CameraExid
  cameraStatusLogs: CameraStatusLog[]
  cameras: Camera[]
}

export const useTimelineStore = defineStore({
  id: "timeline",
  state: (): TimelineState => ({
    isQueryParamsEmpty: false,
    showOverlay: false,
    showCompare: false,
    overlayType: undefined,
    overlayData: undefined,
    _360Id: undefined,
    droneId: undefined,
    mediaId: undefined,
    fromDate: undefined,
    toDate: undefined,
    timestamp: undefined,
    isTimelineInitialized: false,
    snapshotsInterval: { from: "", to: "" },
    groupsStatuses: Object.values(TimelineGroupId).reduce((acc, type) => {
      return {
        ...acc,
        [type]: TaskStatus.Idle,
      }
    }, {} as Record<TimelineGroupId, TaskStatus>),
    cameraExid: undefined,
    cameraStatusLogs: [],
    cameras: [],
  }),
  actions: {
    async init(query: TimelineUrlParams) {
      this.isQueryParamsEmpty = !Object.keys(query || {})?.length
      await this.initSelectedCamera()
      await this.initRecordingsStoreState(query)
      this.initVideoStreaming()
      this.fetchEventsGroups()
      this.updatePlayerSnapshotsInterval()
      this.isTimelineInitialized = true
    },
    async initSelectedCamera() {
      const projectCameras = this.projectStore.selectedProject?.cameras

      if (!projectCameras?.length) {
        return
      }
      const cameraExid =
        this.cameraExid || (projectCameras[0]?.id as CameraExid)
      await this.selectCamera(cameraExid)
    },
    async selectCamera(cameraExid: CameraExid) {
      this.cameraExid = cameraExid
      if (this.cameraStore.selectedCameraExid !== cameraExid) {
        await this.cameraStore.selectCamera(cameraExid)
        await this.fetchStatusLogsForCamera(cameraExid)
      }
      await useTimelineCommentsGroupStore().filterCommentsByInterval(
        this.snapshotsInterval
      )

      await this.initVideoStreaming()
    },
    async initRecordingsStoreState(query: TimelineUrlParams) {
      const timestamp = query[TimelineUrlParam.Timestamp]
      this.recordingsStore.isLive =
        query[TimelineUrlParam.Live] === UrlParamValue.True ||
        (!timestamp && this.selectedCamera?.status === CameraStatus.Online)
      this.recordingsStore.isEdgeVideo =
        query[TimelineUrlParam.Video] === UrlParamValue.True
      this.recordingsStore.selectedTimestamp = await this.initSelectedTimestamp(
        timestamp
      )
    },
    async initSelectedTimestamp(urlTimestamp?: string) {
      await this.updateSnapshotStoreIfNeeded()

      const isUrlTimestampValid = urlTimestamp && moment(urlTimestamp).isValid()

      return isUrlTimestampValid
        ? urlTimestamp
        : this.snapshotStore.latestSnapshotTimestamp
    },
    async updateSnapshotStoreIfNeeded() {
      const cameraExid = this.cameraStore.selectedCamera.id
      if (
        !this.snapshotStore.cameraExid ||
        this.snapshotStore.cameraExid !== cameraExid ||
        !this.snapshotStore.latestSnapshotTimestamp
      ) {
        await this.snapshotStore.updateLatestAndOldestSnapshots(cameraExid)
      }
    },
    async initVideoStreaming() {
      if (useTimelineExNvrGroupStore().hasEdgeVideoStreaming) {
        await this.initEdgeStreaming()
      }
    },
    async initEdgeStreaming() {
      await this.recordingsStore.createEdgeStreamingSession({
        cameraExid: this.cameraStore.selectedCamera?.id,
        token: this.accountStore.token,
      })
      await this.recordingsStore.fetchExNvrAvailableRecordings()
    },
    async fetchEventsGroups() {
      this.fetchMediaHubItems()

      if (this.has360) {
        this.fetch360Walks()
      }

      if (this.hasDrone) {
        this.fetchDroneFlights()
      }

      this.fetchMobileCaptureSnapshots()

      this.fetchComments()
    },
    async performGroupRequest(groupId, requestFunction) {
      this.groupsStatuses[groupId] = TaskStatus.Loading
      try {
        await requestFunction()
        this.groupsStatuses[groupId] = TaskStatus.Success
      } catch (error) {
        console.error(error)
        this.groupsStatuses[groupId] = TaskStatus.Error
      }
    },
    async fetch360Walks() {
      await this.performGroupRequest(TimelineGroupId.ThreeSixty, async () => {
        const { dates } = await _3dFirebaseApi._360.getProjectInfo(
          this.selectedProject.exid
        )
        if (dates?.length && this.isQueryParamsEmpty) {
          useTimeline360GroupStore().show360 = true
        }
        useTimeline360GroupStore()._360WalksDates = dates || []
      })
    },
    async fetchDroneFlights() {
      await this.performGroupRequest(TimelineGroupId.Drone, async () => {
        const { models } = await _3dFirebaseApi.drone.getProjectInfo(
          this.selectedProject.exid
        )
        if (models?.length && this.isQueryParamsEmpty) {
          useTimelineDroneGroupStore().showDrone = true
        }
        useTimelineDroneGroupStore().droneFlights = models || []
      })
    },
    async fetchMobileCaptureSnapshots() {
      await this.performGroupRequest(
        TimelineGroupId.MobileCapture,
        async () => {
          let snapshots = await IngestApi.mobileCapture.getProjectMobileAssets({
            projectId: this.selectedProject.exid,
            assetType: INGEST_MOBILE_PHOTO_ASSET_TYPE,
          })

          if (
            snapshots?.length >
            useTimelineMobileCaptureGroupStore().mobileCaptureSnapshots.length
          ) {
            useTimelineMobileCaptureGroupStore().mobileCaptureSnapshots =
              snapshots || ([] as MobileCaptureSnapshot[])
          }

          if (snapshots?.length && this.isQueryParamsEmpty) {
            useTimelineMobileCaptureGroupStore().showMobileCapture = true
          }
        }
      )
    },
    async fetchComments() {
      await this.performGroupRequest(TimelineGroupId.Comments, async () => {
        const params: CommentsRequestPayload = {
          cameraExid: this.cameraExid as CameraExid,
          fromDate: moment(this.selectedProject.startedAt).format("YYYY-MM-DD"),
          toDate: moment(new Date()).format("YYYY-MM-DD"),
          page: 1,
          limit: 1000,
        }

        const { items: activeComments }: PaginatedItems<Comment> =
          await EvercamApi.comments.fetchComments(
            this.selectedProject.exid,
            params
          )

        const { items: archivedComments }: PaginatedItems<Comment> =
          await EvercamApi.comments.fetchComments(this.selectedProject.exid, {
            ...params,
            archived: true,
          })

        useTimelineCommentsGroupStore().comments = activeComments

        useTimelineCommentsGroupStore().archivedComments = archivedComments
      })
    },
    async fetchMediaHubItems() {
      await this.performGroupRequest(TimelineGroupId.Media, async () => {
        const params: MediaFilterQueryParams = {
          dateRange: [
            moment(this.selectedProject.startedAt).format("YYYY-MM-DD"),
            moment(new Date()).format("YYYY-MM-DD"),
          ],
          page: 1,
          limit: 1000,
        }

        const { items }: PaginatedItems<Media> =
          await EvercamApi.mediaHub.getAllMedia(
            this.selectedProject.exid,
            params
          )

        const itemsByType = camelizeKeys(
          items.reduce((acc, a) => {
            if (a.status !== MediaStatus.Completed) {
              return acc
            }

            return { ...acc, [a.type]: [...(acc[a.type] || []), a] }
          }, {})
        ) as TimelineMediaHubItemsByType

        useTimelineMediaHubGroupStore().mediaHubItemsByType = {
          clip: itemsByType.clip || [],
          compare: itemsByType.compare || [],
          edit: itemsByType.edit || [],
          timelapse: itemsByType.timelapse || [],
          xRay: itemsByType.xRay || [],
        }
      })
    },
    async fetchStatusLogsForCamera(cameraExid: CameraExid) {
      try {
        const result = await EvercamApi.cameras.getCameraStatusLogs(
          cameraExid,
          {
            params: {
              from: `${moment(this.selectedProject.startedAt).format(
                "YYYY-MM-DDTHH:mm:ss"
              )}.000Z`,
              to: `${moment(new Date()).format("YYYY-MM-DDTHH:mm:ss")}.000Z`,
            },
          }
        )
        this.cameraStatusLogs = result
      } catch (error) {
        console.error(`Error fetching logs for camera ${cameraExid}:`, error)

        this.cameraStatusLogs = []
      }
    },
    updatePlayerSnapshotsInterval(): void {
      const target = this.recordingsStore.selectedTimestamp || new Date()
      const from = moment(target).subtract(2, "minutes")
      const to = moment(target).add(8, "minutes")
      const now = moment(new Date())

      this.snapshotsInterval = {
        from: from.format(),
        to: moment.min(to, now).format(),
      }
    },
    selectTimestamp(t: string) {
      console.log("selectTimestamp", t)
      if (this.recordingsStore.isLive) {
        this.recordingsStore.isLive = false
      }
      this.recordingsStore.selectedTimestamp = t
      this.timestamp = t
      this.updatePlayerSnapshotsInterval()
      useTimelineCommentsGroupStore().filterCommentsByInterval(
        this.snapshotsInterval
      )
      useTimelineObjectInspectorGroupStore().onTimestampSelected()
    },
    changeCompareVisibility(visibilityStatus) {
      if (this.showCompare === visibilityStatus) {
        return
      }
      this.showCompare = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(AnalyticsEvent.Compare, {
        visible: visibilityStatus,
      })
    },
    onCopilotVisibilityChange() {
      if (useCopilotStore().isInRightSideBar) {
        useTimelineBimGroupStore().changeBimeMilestonesVisibility(false)
        this.recordingsStore.isXrayActive = false
      }
    },
  },
  getters: {
    groups(): TimelineGroup[] {
      return [
        useTimelineAnprGroupStore().group,
        useTimeline360GroupStore().group,
        useTimelineDroneGroupStore().group,
        useTimelineMediaHubGroupStore().group,
        useTimelineBimGroupStore().group,
        useTimelineMobileCaptureGroupStore().group,
        useTimelineExNvrGroupStore().group,
        useTimelineCommentsGroupStore().group,
        useTimelineSiteActivityGroupStore().group,
        useTimelineSafetyReportsGroupStore().group,
        useTimelineConstructionReportsGroupStore().group,
        useTimelineObjectInspectorGroupStore().group,
        useTimelineLuminanceGroupStore().group,
      ]
    },
    groupsConfigs(): Record<string, TimelinePlayerGroupConfig> {
      let groups = this.groups.reduce(
        (acc, { id, isVisible, timelineConfig, hasMultipleGroups }) => {
          if (
            !isVisible ||
            (id === TimelineGroupId.Comments &&
              !useTimelineCommentsGroupStore().hasActiveComments)
          ) {
            return acc
          }

          if (hasMultipleGroups) {
            return {
              ...acc,
              ...timelineConfig,
            }
          } else {
            return {
              ...acc,
              [id]: timelineConfig,
            }
          }
        },
        {} as Record<string, TimelinePlayerGroupConfig>
      )

      if (Object.keys(groups)?.length === 0) {
        groups = {
          placeholder: {
            label: "",
            color: TimelineColors.placeholder,
            chartType: TimelineChartType.Milestones,
            height: 60,
          },
        }
      }

      return groups
    },
    groupsVisibility(): Record<TimelineGroupId, boolean> {
      return this.groups.reduce(
        (acc, { id, isVisible }) => ({ [id]: isVisible, ...acc }),
        {} as Record<TimelineGroupId, boolean>
      )
    },
    disabledGroups(): Record<TimelineGroupId, boolean> {
      const isLoading = (id: TimelineGroupId) =>
        [TimelineGroupId.Media].includes(id)
          ? this.groupsStatuses[id] === TaskStatus.Loading
          : false

      return this.groups.reduce(
        (acc, { id, isDisabled }) => ({
          [id]: isDisabled || isLoading(id),
          ...acc,
        }),
        {} as Record<TimelineGroupId, boolean>
      )
    },
    hiddenGroups(): Array<TimelineGroupId> {
      return this.groups.reduce(
        (acc, { id, isRestricted }) => (isRestricted ? [id, ...acc] : acc),
        [] as TimelineGroupId[]
      )
    },
    persistentGroups(): Array<TimelineGroupId> {
      return this.groups.reduce(
        (acc, { id, isPersistent }) => (isPersistent ? [id, ...acc] : acc),
        [] as TimelineGroupId[]
      )
    },
    $permissions() {
      return useNuxtApp().nuxt2Context.$permissions
    },
    projectStore(): ReturnType<typeof useProjectStore> {
      return useProjectStore()
    },
    cameraStore(): ReturnType<typeof useCameraStore> {
      return useCameraStore()
    },
    selectedCamera(): Camera | null {
      return this.cameraStore.selectedCamera as Camera
    },
    recordingsStore(): ReturnType<typeof useRecordingsStore> {
      return useRecordingsStore()
    },
    snapshotStore(): ReturnType<typeof useSnapshotStore> {
      return useSnapshotStore()
    },
    accountStore(): ReturnType<typeof useAccountStore> {
      return useAccountStore()
    },
    timezone(): string {
      return this.projectStore.selectedProject?.timezone
    },
    selectedProject(): Project {
      return this.projectStore.selectedProject as Project
    },
    hasLiveView(): boolean {
      return (
        this.cameraStore.hasCameraLiveView ||
        useTimelineExNvrGroupStore().hasEdgeVideoStreaming
      )
    },
    has360(): boolean {
      return (
        this.$permissions.project.has._360View() &&
        this.$permissions.user.can.access._360()
      )
    },
    hasDrone(): boolean {
      return this.$permissions.project.has.droneView()
    },
    hasObjectInspector(): boolean {
      return (
        this.$permissions.user.is.admin() &&
        this.cameraStore.selectedCamera.featureFlags.includes(
          CameraFeatureFlag.Segmentation
        )
      )
    },
  },
  syncWithUrl: Object.values(TimelineUrlParam) as (keyof TimelineState)[],
})
