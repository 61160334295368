import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import {
  AnalyticsEvent,
  Camera,
  Project,
  TimelineGroup,
  TimelineGroupId,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { TimelineChartType } from "@evercam/ui"
import {
  TimelineDataProvider,
  TimelineLuminanceProvider,
} from "@evercam/shared/components/timelinePlayer/providers"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

const groupId = TimelineGroupId.Luminance

interface TimelineLuminanceGroupState {
  showLuminance: boolean
}

export const useTimelineLuminanceGroupStore = defineStore({
  id: "timelineLuminanceGroup",
  state: (): TimelineLuminanceGroupState => ({
    showLuminance: false,
  }),
  getters: {
    selectedProject(): Project {
      return useProjectStore().selectedProject as Project
    },
    cameras(): Camera[] {
      return this.selectedProject.cameras as Camera[]
    },
    luminanceCamera(): Camera | null {
      return this.cameras.find((c) => c.exid === "echel-mdqur")
    },
    luminanceEventsProvider(): TimelineDataProvider {
      return new TimelineLuminanceProvider({
        projectExid: useProjectStore().selectedProject?.exid,
        cameraExid: this.luminanceCamera?.exid,
        token: useAccountStore().token,
        timezone: this.selectedProject?.timezone,
      })
    },
    isDisabled(): boolean {
      return !this.luminanceCamera
    },
    isVisible(): boolean {
      return this.showLuminance && !this.isDisabled
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        timelineConfig: {
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}`)
            .toString(),
          color: TimelineColors.primary,
          chartType: TimelineChartType.Dots,
          height: 54,
          dotsSize: 16,
          provider: this.luminanceEventsProvider,
          groupOverlappingDots: true,
          getChartType: (_precision) => TimelineChartType.Dots,
        },
      }
    },
  },
  actions: {
    changeLuminanceVisibility(visibilityStatus) {
      if (this.showLuminance === visibilityStatus) {
        return
      }

      this.showLuminance = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.GroupsVisibilityLuminance,
        { visible: visibilityStatus }
      )
    },
  },
})
