import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import {
  AnalyticsEvent,
  Camera,
  CameraFeatureFlag,
  Project,
  TimelapseActivityType,
  TimelapseReportType,
  TimelineGroup,
  TimelineGroupId,
  TimelinePlayerGroupConfig,
  TimelineSiteActivityUrlParams,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { TimelineTimelapseReportsProvider } from "@evercam/shared/components/timelinePlayer/providers"

const groupId = TimelineGroupId.SiteActivity

interface TimelineSiteActivityGroup {
  showSiteActivity: boolean
}

export const useTimelineSiteActivityGroupStore = defineStore({
  id: "timelineSiteActivityGroup",
  state: (): TimelineSiteActivityGroup => ({
    showSiteActivity: false,
  }),
  getters: {
    $permissions() {
      // @ts-ignore
      return useNuxtApp().nuxt2Context.$permissions
    },
    selectedProject(): Project {
      return useProjectStore().selectedProject as Project
    },
    cameras(): Camera[] {
      return this.selectedProject.cameras as Camera[]
    },
    timelapseReportsCamera(): Camera | null {
      return this.cameras.find((c) =>
        c.featureFlags.includes(CameraFeatureFlag.CopilotTimelapseReport)
      )
    },
    isDisabled(): boolean {
      return !this.timelapseReportsCamera
    },
    isVisible(): boolean {
      return this.showSiteActivity && !this.isDisabled
    },
    isRestricted(): boolean {
      return !this.$permissions.user.is.admin() || this.isDisabled
    },
    siteActivityGroups(): Record<string, TimelinePlayerGroupConfig> {
      return Object.values(TimelapseActivityType).reduce(
        (acc, activityType) => {
          return {
            ...acc,
            [groupId + activityType]: {
              label: useNuxtApp()
                .vue2App.$i18n.t(
                  `content.etimeline.labels.${groupId}_${activityType}`
                )
                .toString(),
              color: TimelineColors[activityType],
              provider: new TimelineTimelapseReportsProvider({
                reportType: TimelapseReportType.SiteActivity,
                projectExid: useProjectStore().selectedProject?.exid,
                token: useAccountStore().token,
                timezone: this.selectedProject?.timezone,
                eventsFilter: (e) => e.activityType === activityType,
                countsFilter: (id) => id === activityType,
              }),
            },
          }
        },
        {}
      )
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        isRestricted: this.isRestricted,
        hasMultipleGroups: true,
        timelineConfig: this.siteActivityGroups,
      }
    },
  },
  actions: {
    changeSiteActivityVisibility(visibilityStatus) {
      if (this.showSiteActivity === visibilityStatus) {
        return
      }
      this.showSiteActivity = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.GroupsVisibilitySiteActivity,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineSiteActivityUrlParams
  ) as (keyof TimelineSiteActivityGroup)[],
})
